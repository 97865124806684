'use client';

import { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/navigation';
import useIsVisible from 'ui/components/hooks/useIsVisible';
import Image from 'next/image';
import { deleteCookie, getCookie, setCookie } from 'cookies-next';
import { getNewCheckoutFlag } from 'harness-react';
import logger from 'logger';
import cn from 'ui/lib/utils';
import { ArrowForwardIcon } from 'ui/icons';
import CircularProgress from '@mui/material/CircularProgress';
import getMonth from '../../../helpers/getMonth';
import CONSTANTS from '../../../../constants/cookieConstants';
import currencyConverter from '../../../helpers/currencyConverter';

function ProductCard({ item, index, handleSelect, selected }) {
  getMonth('item in productCard', item);

  const iconTextData = item?.productDescriptionFromAEM?.sort((a, b) => a.order - b.order);
  const { topBannerText, subtextLeftOne, subtextLeftTwo, subtextRightOne } =
    item?.productAdditionalInfoFromAEM || {};

  const onKeyDown = (event) => {
    event.preventDefault();
    if (event.key === 'Enter' || event.key === ' ') {
      handleSelect();
    }
  };

  return (
    <div
      role='button'
      tabIndex='0'
      className={cn(
        { 'border-blue-600': selected },
        'block my-6 rounded-lg border-2 border-solid overflow-hidden bg-white cursor-pointer text-left',
      )}
      key={item.productId}
      data-testid={`productListingNumber${index}`}
      onClick={handleSelect}
      onKeyDown={onKeyDown}
    >
      {topBannerText?.html && (
        <div
          className='text-white bg-blue-600 py-1 px-6'
          dangerouslySetInnerHTML={{ __html: topBannerText?.html }}
        />
      )}
      <div className='my-4 mx-6'>
        <div className='grid grid-cols-12 gap-y-2 md:gap-0'>
          <h4 data-testid='productName' className='col-span-9'>
            {item.productName}
          </h4>

          <div className='h4 col-span-3 text-right'>
            {currencyConverter(item?.productCurrency)}
            {item?.productPrice}
          </div>
          <div className='col-span-4'>
            <span className='inline-block small' dangerouslySetInnerHTML={{ __html: subtextLeftOne?.html }} />
          </div>
          <div className='col-span-8 text-right'>
            <span
              className='inline-block small px-2 text-blue-600 bg-blue-600 bg-opacity-25 rounded-sm'
              dangerouslySetInnerHTML={{ __html: subtextRightOne?.html }}
              data-testid={`productListingNumber${index}SubtextRightOne`}
            />
          </div>
        </div>

        {iconTextData && (
          <ul className='flex flex-col gap-2 my-3 py-3 border-t text-gray-600 border-gray-100'>
            {iconTextData.map((iconText) => (
              <li key={iconText.description} className='flex items-center gap-2 small'>
                <Image alt={iconText.description} src={iconText.icon._publishUrl} width={14} height={14} />
                {iconText.description}
              </li>
            ))}
          </ul>
        )}

        <div
          className='xs italic text-gray-600'
          dangerouslySetInnerHTML={{ __html: subtextLeftTwo?.html }}
          data-testid={`productListingNumber${index}SubtextLeftTwo`}
        />
      </div>
    </div>
  );
}

function CtaButton({ className, selectedProduct, onClick, isRedirecting = false }) {
  return (
    <button
      className={cn(
        'button-dark left-4 right-4 z-50 md:static gap-4 justify-between items-center',
        className,
      )}
      type='button'
      data-testid='buyNowButton'
      onClick={() => onClick()}
    >
      {isRedirecting ? (
        <span className='w-full'>
          <CircularProgress
            sx={{
              color: 'colorTheme.primaryColors.light',
            }}
            size={17}
          />
        </span>
      ) : (
        `Buy ${selectedProduct.productName}`
      )}
      <ArrowForwardIcon fontSize='24' className='text-[19px] w-[17px] h-[20px]' />
    </button>
  );
}

export default function ProductCards({ cards }) {
  const [selectedProduct, setSelectedProduct] = useState(cards[0]);
  const [isRedirecting, setRedirecting] = useState(false);

  useEffect(() => {
    setSelectedProduct(cards[0]);
  }, [cards]);

  const locale = 'en-GB';
  const newCheckoutEnabled = getNewCheckoutFlag();
  const router = useRouter();

  const orderItem = async () => {
    logger.info(`orderItem : newCheckoutEnabled = ${newCheckoutEnabled}`);

    setRedirecting(true);

    if (newCheckoutEnabled) {
      const response = await fetch('api/cart', {
        method: 'POST',
        body: JSON.stringify({
          productId: selectedProduct?.productId,
          locale,
        }),
      });

      if (response.ok) {
        const cart = await response.json();
        setCookie(CONSTANTS.CART_ID_KEY, cart?.id, { secure: true });

        router.push('/v2/checkout');
      }
    } else {
      const existingProductId = getCookie(CONSTANTS.PRODUCT_ID_KEY);

      if (existingProductId !== selectedProduct?.productId) {
        deleteCookie(CONSTANTS.CART_ID_KEY);
        setCookie(CONSTANTS.PRODUCT_ID_KEY, selectedProduct?.productId, { secure: true });
      }

      if (selectedProduct?.productId) {
        router.push('/checkout');
      }
    }
  };

  const options = {
    root: null,
    rootMargin: '0px 0px 1000000px 0px',
    threshold: 0,
  };
  const elemRef = useRef();
  const isVisible = useIsVisible(elemRef, options, false, true);

  return (
    <>
      {cards.map((item, index) => (
        <ProductCard
          item={item}
          index={index}
          key={`productCard${item.productId}`}
          handleSelect={() => setSelectedProduct(item)}
          selected={selectedProduct.productId === item.productId}
        />
      ))}
      <CtaButton
        className='w-full md:w-auto flex'
        selectedProduct={selectedProduct}
        onClick={orderItem}
        isRedirecting={isRedirecting}
      />
      <div ref={elemRef} />

      <div
        className={cn(
          'md:hidden bg-brown-100 fixed left-0 right-0 bottom-0 z-50 my-0 px-4 transition-all duration-300',
          isVisible ? 'translate-y-full' : 'translate-y-0',
        )}
      >
        <CtaButton
          className='flex w-full'
          selectedProduct={selectedProduct}
          onClick={orderItem}
          isRedirecting={isRedirecting}
        />
      </div>
    </>
  );
}
